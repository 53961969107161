export const desktopBreakpoint = 1024; // tablet -> desktop
export const tabletBreakpoint = 720; // mobile -> tablet
export const mobileBreakpoint = 360; // minimum website width
export const contentWidth = 1155; // width of section between borders
export const silver_polish = "#c6c6c6";
export const redstone = "#e56b6f";
export const fortress_gray = "#747474";
export const seashell_peach = "#FFF7F2";
export const romantic = "#ffd1b8";
export const off_black = "#313030";
export const jet = "#353434";
export const lively_coral = "#E7777A";

/*

MOBILE VIEW: 360px (minimum) - 768px
TABLET VIEW: 768px - 1366px
DESKTOP VIEW: 1366px


  @media (min-width: ${tabletBreakpoint}px) {
    height: 55px;
    transition: all ease 1s;
  }


*/
